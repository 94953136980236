<template>
  <div id="app">
    <div class="image-container">
      <div>
        <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
      </div>
      <img v-if="imageLoaded" :src="imageDataUrl" alt="Image" class="center-image">
      <div v-else class="loading-text"><i class="el-icon-loading"></i></div>
      <p class="image-text">打开微信扫一扫，快速登录/注册</p>
      <br/>
      <div class="link-container">
        <el-link  type="primary" style="color:black !important;"  href="#/">返回首页</el-link>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  components: {
  
  },
  data() {
    return {
      imageDataUrl: null,
      pkey:null,
      imageLoaded:false,
      intervalId: null
    };
  },
  created() {
    // setInterval(() => {
    //   this.wxislogin()
    // }, 2000)
    this.startPolling()
  },
  mounted() {
    this.wxlogpicgain()
  },
  methods:{
    wxlogpicgain(){
      axios.get('https://api.ipify.org?format=json')
        .then(response => {
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');

          const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

          var data = {"pkey":response.data.ip+'_'+currentTime }
            axios.post("https://dathonlearn.cn/users/wxloginpic/",
                {
                  headers:{
                  'Content-Type':'application/x-www-form-urlencoded'
                },
                data:data}
                ).then(res=>{
                  this.imageDataUrl = `data:image/jpeg;base64,${ res.data.data.pic}`
                  this.pkey = res.data.data.pkey
                  this.imageLoaded = true
                })
        })
        .catch(error => {
          console.log(error);
        });
    },
    wxislogin(){
      var data = {"data":this.pkey}
      axios.post("https://dathonlearn.cn/users/wxislogin/",
    {
      headers:{
      'Content-Type':'application/x-www-form-urlencoded'
    },
    data:data}
    ).then(res=>{
      if(res.data.token!=''){
          let token = "";
          token = res.data.token;
          localStorage.setItem("Authorization", token);
          localStorage.setItem("username", res.data.username);
          this.$router.push("/sql/exe18")
          this.stopPolling()
      }
    })
  },
  startPolling() {
    this.intervalId = setInterval(() => {
      this.wxislogin()
     }, 2000)
    },
  stopPolling() {
    clearInterval(this.intervalId);
    }
  },

};
</script>

<style scoped>
.image-container {
  margin-top: 30px;
  text-align: center;
}

.center-image {
  /* display: inline-block; */
  max-width: 10%;
  vertical-align: middle;
}

.image-text {
  margin-top: 10px;
  font-size:14px;
}


</style>
